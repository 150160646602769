import { mapGetters, mapActions } from 'vuex'

const moduleName = 'JobInvoiced'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
      // readOnlyView: 'readOnlyView'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions('quotes', { setQuoteReturnRoute: 'setReturnRoute', editStoreQuote: 'editStoreItem' }),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}