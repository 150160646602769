import { TableColumn } from '@/classes'

const DetailColumns = [
  new TableColumn('Quote No.', 'quoteNoRef', true, 'asc', 'left'),
  new TableColumn('Job Stage', 'jobStage', true, 'desc', 'left'),
  new TableColumn('Owner', 'owner', true, 'desc', 'left'),
  new TableColumn('Rego', 'rego', true, 'desc', 'left'),
  new TableColumn('Vehicle', 'MakeModel', true, 'desc', 'left'),
  new TableColumn('Debtor Name', 'debtorName', true, 'desc', 'left'),
  new TableColumn('Invoice Date', 'invoiceDate', true, 'desc', 'left'),
  new TableColumn('Invoice No.', 'invoiceNoRef', true, 'desc', 'left'),
  new TableColumn('Total', 'totalIncGst', true, 'asc', 'right')
]

const MonthlyColumns = [
  new TableColumn('Month', 'fiscalMonthOrder', true, 'desc', 'left'),
  new TableColumn('Working Days', 'workingDays', true, 'desc', 'centered'),
  new TableColumn('Total Invoices', 'totalInvoices', true, 'desc', 'centered'),
  new TableColumn('Avg. Daily Invoiced', 'avgDailyInvoie', true, 'asc', 'right'),
  new TableColumn('Monthly Target', 'otherTotal', true, 'asc', 'right'),
  new TableColumn('Total', 'totalIncGst', true, 'asc', 'right')
]

export { DetailColumns, MonthlyColumns }
